<template>
    <div class="invite">
        <div class="header">
            <van-nav-bar :title="$t('home.yq_auth')" left-arrow @click-left="$router.go(-1)" />
        </div>
        <div class="maincontent">
            <div class="spacing">
                <div class="spacing-header">
                    <div class="headers_ww"></div>
                    <div class="mains">
                        <div class="conts">
                            <h2>{{ $t('invite.code') }}</h2>
                            <p>{{ user.invite_code }}</p>
                            <!-- <img :src="require('../../assets/img/code.png')" /> -->
                            <img :src="imgUrl" />
                            <div class="links">
                                <span @click.prevent="copyurl" class="icon-copy" :data-clipboard-text="urls">{{
                                    $t('invite.copy') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="lines">
                    <div class="conts">
                        <div class="line"></div>
                        <div class="quare quare-left"></div>
                        <div class="quare quare-rigth"></div>
                    </div>
                </div>
                <div class="spacing-main">
                    <div class="conts">
                        <div class="conts-header">
                            <div class="header_qwe">
                                <span>{{ $t('invite.title') }}</span>
                            </div>
                            <div class="statistics">
                                <div class="statistics-conts">
                                    <div class="ert">
                                        <p>{{ certifiedNum }}{{ $t('invite.person') }}</p>
                                        <span>{{ $t('invite.certified') }}</span>
                                    </div>
                                    <div class="ert">
                                        <p>{{ notCertifiedNum }}{{ $t('invite.person') }}</p>
                                        <span>{{ $t('invite.notCertified') }}</span>
                                    </div>
                                    <div class="lines-cont"></div>
                                </div>

                            </div>
                        </div>

                        <div class="cont-list">
                            <div class="list" v-for="(item, index) in list" :key="index">
                                <div class="list-headers">
                                    <h1>{{ index + 1 === 1 ? $t('invite.ones') : (index + 1 === 2 ? $t('invite.twos') :
                                        $t('invite.threes')) }}</h1>
                                    <div class="title-lines"></div>
                                </div>
                                <div class="list-cont">
                                    <div class="statistics-conts">
                                        <div class="ert">
                                            <p>{{ item.certified }}{{ $t('invite.person') }}</p>
                                            <span>{{ $t('invite.certified') }}</span>
                                        </div>
                                        <div class="ert">
                                            <p>{{ item.notCertified }}{{ $t('invite.person') }}</p>
                                            <span>{{ $t('invite.notCertified') }}</span>
                                        </div>
                                        <div class="lines-cont"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Clipboard from 'clipboard'
export default {
    data() {
        return {
            code: {
                img: ''
            },
            list: [
                {
                    certified: 0,
                    notCertified: 0
                },
                {
                    certified: 0,
                    notCertified: 0
                },
                {
                    certified: 0,
                    notCertified: 0
                }
            ],
            certifiedNum: 0,
            notCertifiedNum: 0,
            urls: '',
            imgUrl: '',
            user: {}
        }
    },
    created() {
        this.init();
    },
    mounted() {
        // this.certifiedNum = this.list.map(item => item.certified).reduce((prev, curr) => prev + curr, 0)
        // this.notCertifiedNum = this.list.map(item => item.notCertified).reduce((prev, curr) => prev + curr, 0)
        this.urls = window.location.href
        // this.init();
    },
    methods: {
        // 复制地址
        copyurl() {
            const clipboard = new Clipboard('.icon-copy')
            clipboard.on('success', e => {
                this.$toast.success(this.$t('common.success'))
            })
        },
        async init() {
            const { data } = await this.$http.get('/home/commission/index')
            console.log('邀请返回的数据: ', data.data);
            if(data.code == 200) {
                this.certifiedNum = data.data.total?.oauth_users
                this.notCertifiedNum = data.data.total?.no_oauth_users
                let arrLists = []
                if(data.data.first) {
                    arrLists.push({
                        certified: data.data.first.oauth_users || 0,
                        notCertified: data.data.first.no_oauth_users || 0,
                    })
                }
                if(data.data.second) {
                    arrLists.push({
                        certified: data.data.second.oauth_users || 0,
                        notCertified: data.data.second.no_oauth_users || 0,
                    })
                }
                if(data.data.three) {
                    arrLists.push({
                        certified: data.data.three.oauth_users || 0,
                        notCertified: data.data.three.no_oauth_users || 0,
                    })
                }
                this.list = arrLists
                this.imgUrl = data.data.invite_qrcode || ''
                this.user = data.data || ''
                this.urls = window.location.origin+'/#/register?inviter_code='+data.data.invite_code
                console.log('邀请返回的数据: ', arrLists);
            } else {
                this.$toast.fail(data.msg)
            }
        },
    }
}
</script>
<style lang="less" scoped>
.invite {
    min-height: 100%;
}

.maincontent {
    padding-top: 50px;
    flex: 1;
    font-size: 0.93333rem;
    background: #0055ff;
    height: 100vh;

    .spacing {
        margin-top: 1.2rem;
        padding: 0 1rem 1.2rem 1rem;
        background: #0055ff;

        .spacing-header {

            .headers_ww {
                width: 100%;
                height: 1rem;
                background: #FEB545;
                border-radius: 1rem;

            }

            .mains {
                padding: 0 1.2rem;
                margin-top: -0.5rem;

                .conts {
                    min-height: 10rem;
                    background: #fff;
                    padding-bottom: 1rem;

                    h2 {
                        padding-top: 1.2rem;
                        font-size: 0.933rem;
                        color: #464646;
                        line-height: 1;
                    }

                    p {
                        margin-top: 0.5rem;
                        font-size: 1.2rem;
                        line-height: 1;
                        color: #0055ff;
                        font-weight: bold;
                    }

                    img {
                        display: inline-block;
                        width: 9.4rem;
                        height: 9.4rem;
                        margin-top: 0.5rem;
                    }

                    .links {
                        padding: 0 4rem;
                        margin-top: 0.5rem;

                        span {
                            display: inline-block;
                            width: 100%;
                            font-size: 0.933rem;
                            padding: 0.8rem 0;
                            background: rgba(0, 85, 255, .2);
                            color: #0055ff;
                            line-height: 1;
                            border-radius: 0.4rem;
                        }
                    }
                }
            }
        }

        .lines {
            padding: 0 1.2rem;
            margin-top: -0.1rem;

            .conts {
                background: #fff;
                height: 1.6rem;
                position: relative;

                .line {
                    position: absolute;
                    height: 1px;
                    width: 100%;
                    top: 50%;
                    border-top: 1px dashed rgba(0, 85, 255, .2);
                }

                .quare {
                    height: 1.6rem;
                    width: 1.6rem;
                    background: #0055ff;
                    border-radius: 1.6rem;
                    position: absolute;
                    top: 0;
                }

                .quare-left {
                    left: -0.8rem;
                }

                .quare-rigth {
                    right: -0.8rem;
                }
            }

        }

        .spacing-main {
            padding: 0 1.2rem;
            margin-top: -0.1rem;

            .conts {
                padding: 0.8rem;
                background: #F8F8F8;
                min-height: 4rem;

                .conts-header {
                    margin-top: 1rem;
                    background: #fff;
                    border-radius: 0.4rem;
                    position: relative;

                    .header_qwe {
                        width: 12.353rem;
                        height: 3rem;
                        // background: #FEB545;
                        background: url('../../assets/img/title-bg.png');
                        background-size: cover;
                        position: absolute;
                        left: 50%;
                        margin-left: -6.176rem;
                        margin-top: -0.76rem;

                        span {
                            font-size: 0.933rem;
                            color: #333;
                            line-height: 1;
                            display: inline-block;
                            margin-top: 0.8rem;
                            font-weight: bold;
                        }
                    }

                    .statistics {

                        .statistics-conts {
                            padding-top: 3.4rem;
                            display: flex;
                            justify-content: space-between;
                            position: relative;

                            .ert {
                                width: 49%;
                                height: 3.4rem;

                                p {
                                    font-size: 0.933rem;
                                    color: #000;
                                    line-height: 1;
                                    font-weight: bold;
                                    margin-bottom: 0.6rem;
                                }

                                span {
                                    font-size: 0.833rem;
                                    color: #999;
                                    line-height: 1;
                                }
                            }

                            .lines-cont {
                                position: absolute;
                                height: 2rem;
                                width: 2px;
                                background: #f2f2f2;
                                left: 50%;
                                top: 3.6rem;
                                margin-left: 1px;
                            }
                        }

                    }
                }

                .cont-list {
                    .list {
                        background: #fff;
                        padding: 0.8rem;
                        margin-top: 1rem;
                        border-radius: 0.4rem;

                        .list-headers {
                            text-align: left;
                            position: relative;

                            h1 {
                                font-size: 0.933rem;
                                color: #333;
                                line-height: 1;
                                font-weight: bold;
                                padding-left: 8px;
                            }

                            .title-lines {
                                width: 0.933rem;
                                height: 4px;
                                background-color: #FFB86A;
                                border-radius: 4px;
                                position: absolute;
                                transform: rotate(90deg);
                                top: 4px;
                                left: -6px;
                            }
                        }

                        .list-cont {
                            margin-top: 1.2rem;

                            .statistics-conts {
                                display: flex;
                                justify-content: space-between;
                                position: relative;

                                .ert {
                                    width: 49%;
                                    height: 3.4rem;

                                    p {
                                        font-size: 0.933rem;
                                        color: #000;
                                        line-height: 1;
                                        font-weight: bold;
                                        margin-bottom: 0.6rem;
                                    }

                                    span {
                                        font-size: 0.833rem;
                                        color: #999;
                                        line-height: 1;
                                    }
                                }

                                .lines-cont {
                                    position: absolute;
                                    height: 2rem;
                                    width: 2px;
                                    background: #f2f2f2;
                                    left: 50%;
                                    top: 0.6rem;
                                    margin-left: 1px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
